import React, { useState } from 'react';
import { CustomizeListProps } from '../../types/CustomizeListProps';
import { TableHeadersProp } from '../../types/TableHeadersProp';
import DndBoard from './DndBoard';
import Modal from './Modal';

function CustomizeList(props: CustomizeListProps): JSX.Element {
  const { setOpenModal, headerList, setHeaders, customHeaders = [], defaultHeaders } = props;

  const [editedHeaders, setEditedHeaders] = useState<TableHeadersProp[]>(headerList);

  return (
    <Modal
      width="672"
      height="701"
      title="Customize list columns"
      setModalOpen={() => setOpenModal(0)}
      tertiaryButtonLabel="Cancel"
      tertiaryButtonAction={() => {
        setOpenModal(0);
      }}
      secondaryButtonLabel="Revert to default"
      secondaryButtonAction={() => {
        setHeaders(defaultHeaders);
        setOpenModal(0);
      }}
      primaryButtonLabel="Save"
      primaryButtonAction={() => {
        setHeaders(editedHeaders);
        setOpenModal(0);
      }}
      className="customize-fields"
    >
      <DndBoard headers={headerList} customHeaders={customHeaders} setEditedHeaders={setEditedHeaders} defaultHeaders={defaultHeaders} />
    </Modal>
  );
}

export default CustomizeList;
